import { useEffect, useState } from "react";
import { createContext } from "use-context-selector";
import { useImmer } from "use-immer";
import useStore from "../hooks/useStore";
import useWebService from "./../hooks/useWebService";
export const OrcComplementarContext = createContext(null);

export default function OrcComplementarProvider({ children }) {
  const { WebService } = useWebService();
  const { setChave, reset, chave: prevChave } = useStore();
  const [isLoading, setLoading] = useState("idle");
  const [erro, setErro] = useState(null);
  const [orcamento, setOrcamento] = useImmer({});

  useEffect(() => {
    if (orcamento == null) {
      let tempData = window.sessionStorage.getItem("create-reset-storage");
      if (tempData != null && tempData !== undefined) {
        console.log("ATENDIMENTO SESSION STORAGE", { tempData });
        setOrcamento(JSON.parse(tempData));
      }
    }
  }, [orcamento, setOrcamento]);

  const getOrcamento = async (chave, history) => {
    setLoading("loading");
    if (chave !== prevChave) reset();
    try {
      //0EB7B2FC8C59C66FA9D4A7D15850B28DF3114CAC8A8F8C3C56FBE43B222C5ACD
      const response = await WebService.get(
        `buscaOrcamentoOrcComplementar?chave=${chave}`
      );
      console.log("REQUEST ATENDIMENTO", chave);
      console.log("REQUEST ATENDIMENTO", response);
      if (!response.data.statusRetorno.sucesso) {
        throw new Error(response.data.statusRetorno.mensagemErro);
      }
      setOrcamento(response.data);
      setChave(chave);
      setLoading("idle");
      window.sessionStorage.setItem(
        "create-reset-storage",
        JSON.stringify(response.data)
      );
    } catch (error) {
      setChave(null);
      history.push("/key-error");
      setLoading("error");
      setErro(error);
    }
  };

  const setOrcamentoFinalizado = () => {
    setOrcamento({
      ...orcamento,
      orcamentoFinalizado: true,
    });
  };

  return (
    <OrcComplementarContext.Provider
      value={{
        isLoading,
        erro,
        orcamento,
        getOrcamento,
        setOrcamentoFinalizado,
      }}
    >
      {children}
    </OrcComplementarContext.Provider>
  );
}

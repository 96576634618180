import { useContextSelector } from "use-context-selector";
import { OrcComplementarContext } from "../context/OrcComplementar";

function useOrcamento() {
  const isLoading = useContextSelector(
    OrcComplementarContext,
    (state) => state.isLoading
  );
  const erro = useContextSelector(
    OrcComplementarContext,
    (state) => state.erro
  );

  
  const atendimentoCL = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.atendimentoCL
  );
  const revenda = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.revenda
  );
  
  const password = useContextSelector(
    OrcComplementarContext,
    (state) => state.changePassword?.password
  );
  const confirmPassword = useContextSelector(
    OrcComplementarContext,
    (state) => state.changePassword?.confirmPassword
  );

  return {
    isLoading,
    erro,
    atendimentoCL,
    revenda,
    confirmPassword,
    password
  };
}

export default useOrcamento;

import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import produce from "immer";

const persistStore = {
  name: "state",
  getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
};

const store = (set, get) => ({
  password: '',
  confirmPassword: '',
  type: null,
  chave: null,
  errorMessage: null,
  success: false,
 
    setPassword: (password) =>
    set(
      produce((oldState) => {
        oldState.password = password;
      })
    ),
    setSuccess: (password) =>
    set(
      produce((oldState) => {
        oldState.success = password;
      })
    ),
    setErrorMessage: (errorMessage) =>
    set(
      produce((oldState) => {
        oldState.errorMessage = errorMessage;
      })
    ),
    setConfirmPassword: (confirmPassword) =>
    set(
      produce((oldState) => {
        oldState.confirmPassword = confirmPassword;
      })
    ),
  setType: (type) =>
    set(
      produce((oldState) => {
        oldState.type = type;
      })
    ),
  setChave: (chave) =>
    set(
      produce((oldState) => {
        oldState.chave = chave;
      })
    ),
  reset: () =>
    set(
      produce((oldState) => {
        oldState.type = null;
        oldState.password = null;
        oldState.confirmPassword = null;
        oldState.chave = null;
        oldState.errorMessage = null;
      })
    ),
});

const useStore = create(devtools(persist(store, persistStore)));

export default useStore;

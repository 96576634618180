import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    margin:0;
    padding:0;
    outline:0;
    box-sizing:border-box;
}



body,html,#root{
    background:#EEE;
    width:100%;
    height:100%;
  
}

#root{
  overflow-x:hidden;
  overflow-y:auto;
}

body,input,button{    
    font-size:100%;
    font-weight:400;
    font-family:"Poppins",Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  /* width: 3px; */
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #00427C33;  */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #00427C;  */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #23231f55;  */
}

.react-modal-overlay {
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-modal-content {
  width: 100%;
  max-width: 576px;
  background-color: #fff;
  padding: 2%;
  position: relative;
  border-radius: 0.4rem;
}

.react-modal-close--btn {
  border: 0;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  background-color: transparent;
  cursor: pointer;
}

`;
export default GlobalStyle;

import React from "react";
import GlobalStyle from "./styles/global";
import { ThemeProvider } from "styled-components";
import TemaDefault from "./styles/theme";
import useOrcamento from "./hooks/useOrcamento";
import Modal from "react-modal";
import Routes from "./routes/routes";
Modal.setAppElement("#root");

function App() {
  const { revenda } = useOrcamento();

  return (
    <ThemeProvider theme={TemaDefault(revenda?.marca?.tema ?? "")}>
      {/* <ThemeProvider theme={TemaDefault("TOYOTA")}> */}
      <Routes />
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;

import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loading from "../components/atoms/Loading";

import PrivateRoutes from "./PrivateRoutes";
const CreatePassword = lazy(() => import("../pages/CreatePassword"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const Acesso = lazy(() => import("../pages/Acesso"));
const NotFound = lazy(() => import("../pages/NotFound"));
const KeyError = lazy(() => import("../pages/KeyError"));
const Admin = lazy(() => import("../pages/Admin"));
const Done = lazy(() => import("../pages/Done"));
export default function Routes() {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<Loading />}>
          {/* PRIMEIRA PAGIAN / INSERIR CHAVE */}
          <PrivateRoutes exact path="/create" component={CreatePassword} />
          <PrivateRoutes exact path="/reset" component={ResetPassword} />
          <Route exact path="/done" component={Done} />
          <Route exact path="/acesso/:tipo/:chave" component={Acesso} />
          <Route exact path="/error" component={NotFound} />
          <Route exact path="/key-error" component={KeyError} />
          <Route exact path="/" component={KeyError} />
          <Route exact path="/admin" component={Admin} />
        </Suspense>
      </Switch>
    </Router>
  );
}
